import { AppDispatch } from "@core/store";
import { clearCreateOrderStores } from "createOrder/@core/common";
import { DashboardActions } from "dashboard/store/dashboardStore";
import { MapSettingsAction } from "map/@store/mapSettingsStore";
import { MapAction } from "map/@store/mapStore";
import { OrderDetailActions } from "orderDetail/@store/orderDetailStore";
import { OrderReferencesActions } from "orderDetail/@store/orderReferencesStore";
import { ReportsAction } from "reports/@store/reportsStore";
import { logout } from "./authStore";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchClient } from "@core/fetchClient";
import { getServerAddress } from "@core/appVariables";

export const logoutHandler = (dispatch: AppDispatch) => {
    dispatch(sendUserLogout());
    clearCreateOrderStores(dispatch);
    dispatch(DashboardActions.clearStore());
    dispatch(MapAction.clearStore());
    dispatch(MapSettingsAction.clearStore());
    dispatch(OrderDetailActions.clearStore());
    dispatch(OrderReferencesActions.clearStore());
    dispatch(ReportsAction.clearStore());
    dispatch(logout());
}

const sendUserLogout = createAsyncThunk(
    'auth/logout',
    async () => {
        await fetchClient().put<void>(`${getServerAddress('/identity/api')}/auth/logout`);
    }
)