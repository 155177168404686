import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAmosBranchDto } from "contracts/amos";
import { ICommonListDto } from "contracts/common";

export interface IDateFilter {
    dateFrom?: number | null,
    dateTo?: number | null,
}

interface IFilterState {
    date?: IDateFilter;
    branch?: string;
    text?: string;
    status?: string;
    branchMultiple?: string[];
    transportationMode?: string[];

    loadState: StateKind;
    branchList: IAmosBranchDto[];
    transportationModeList?: ICommonListDto[];
    startSearch: boolean;
}

const initialState: IFilterState = {
    branchList: [],
    startSearch: false,
    loadState: 'notLoaded'
};

export const getBranchesList = createAsyncThunk(
    'lookup/branches',
    async (branchGuid: string) => {
        const response = await fetchClient().get<IAmosBranchDto[]>(`${getServerAddress('/orders/api')}/lookup/branches/${branchGuid}`);

        return response;
    }
)

export const getTransportationModeList = createAsyncThunk(
    'kpi-dashboard/transportation-mode',
    async () => {
        const response = await fetchClient().get<ICommonListDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/transportation-mode`);

        return response;
    }
)

export const filterStore = createSlice({
    name: 'nal/filter',
    initialState,
    reducers: {
        setDateFilter: (state, action: PayloadAction<IDateFilter>) => {
            state.date = action.payload;
        },
        setBranchFilter: (state, action: PayloadAction<string | undefined>) => {
            state.branch = action.payload;
        },
        setSearchState: (state, action: PayloadAction<boolean>) => {
            state.startSearch = action.payload;
        },
        setTextFilter: (state, action: PayloadAction<string | undefined>) => {
            state.text = action.payload;
        },
        setStatusFilter: (state, action: PayloadAction<string | undefined>) => {
            state.status = action.payload;
        },
        setBranchMultipleFilter: (state, action: PayloadAction<string[] | undefined>) => {
            state.branchMultiple = action.payload;
        },
        setTransportationModeFilter: (state, action: PayloadAction<string[] | undefined>) => {
            state.transportationMode = action.payload;
        },
        clearStore: (state) => {
            const { branchList, loadState } = state;
            return { ...initialState, branchList, loadState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBranchesList.pending, (state: IFilterState) => {
                state.loadState = 'loading';
            })
            .addCase(getBranchesList.fulfilled, (state: IFilterState, action: PayloadAction<IAmosBranchDto[] | undefined>) => {
                state.loadState = 'loaded';
                state.branchList = action.payload || [];
            })
            .addCase(getBranchesList.rejected, (state: IFilterState) => {
                state.loadState = 'failed';
            })
            .addCase(getTransportationModeList.pending, (state: IFilterState) => {
                state.status = 'loading';
            })
            .addCase(getTransportationModeList.fulfilled, (state: IFilterState, action: PayloadAction<ICommonListDto[] | undefined>) => {
                state.transportationModeList = action.payload;
                state.status = 'loaded';
            })
            .addCase(getTransportationModeList.rejected, (state: IFilterState, error) => {
                state.status = 'failed';
            })
    }
});

export const FilterAction = filterStore.actions;

export default filterStore.reducer;