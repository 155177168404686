import SpeedIcon from '@mui/icons-material/Speed';
import BarChartIcon from '@mui/icons-material/BarChart';
import MapIcon from '@mui/icons-material/Map';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { IUserAccessInfo, anyReportsAccess, viewRateAccess } from '@core/permissionsHelper';
import DashboardIcon from '@mui/icons-material/Dashboard';

interface MenuItem {
  title: string;
  path: string;
  iconComponent: () => JSX.Element;
}

const getMenuItems = (accessInfo: IUserAccessInfo): MenuItem[] => {
  const menuItems: MenuItem[] = [
    {
      title: 'Dashboard',
      path: '/',
      iconComponent: () => <SpeedIcon />,
    },
    {
      title: 'Map',
      path: '/map',
      iconComponent: () => <MapIcon />,
    },
    {
      title: 'Power BI',
      path: '/powerbi',
      iconComponent: () => <BarChartIcon />,
    },
  ];

  if (anyReportsAccess(accessInfo)) {
    menuItems.push({
      title: 'Reports',
      path: '/reports',
      iconComponent: () => <AssessmentIcon />,
    });
  }

  if (viewRateAccess(accessInfo)) {
    menuItems.push(
      {
        title: 'KPI Dashboard',
        path: '/kpi-dashboard',
        iconComponent: () => <DashboardIcon />,
      },
    )
  }

  return menuItems;
};

export default getMenuItems;