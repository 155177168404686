import { Theme } from "@mui/material/styles";
import { useTheme } from "@mui/styles";
import { ChartColor } from "app/theme";
import chroma from "chroma-js";

const useAppTheme = () => {
    return useTheme() as Theme;
}

export default useAppTheme;

export const useColorPalette = (numColors: number) => {
    return chroma.scale([
        ChartColor.blue,
        ChartColor.teal,
        ChartColor.green,
        ChartColor.lightGreen,
        ChartColor.orange,
        ChartColor.red,
    ]).mode('lch').colors(numColors);
}