import { configureStore, ThunkAction, Action, Middleware, MiddlewareAPI, isRejectedWithValue, isRejected } from '@reduxjs/toolkit';
import reduxLogger from 'redux-logger';
import notification, { addAppNotification } from 'appNotification/store/appNotificationStore';
import { authReducer } from 'auth';
import { appReducer } from 'app';
import dashboardStore from 'dashboard/store/dashboardStore';
import mapStore from 'map/@store/mapStore';
import orderDetailStore from 'orderDetail/@store/orderDetailStore';
import mapSettingsStore from 'map/@store/mapSettingsStore';
import mapDetailsStore from 'map/@store/mapDetailsStore';
import reportsStore from 'reports/@store/reportsStore';
import createOrderEventStore from 'createOrder/@store/createOrderEventStore';
import createOrderStore from 'createOrder/@store/createOrderStore';
import createOrderPricingStore from 'createOrder/@store/createOrderPricingStore';
import createOrderInfoStore from 'createOrder/@store/createOrderInfoStore';
import createOrderItemsStore from 'createOrder/@store/createOrderItemsStore';
import { logout } from 'auth/store/authStore';
import { AuthHelper } from './authHelper';
import orderReferencesStore from 'orderDetail/@store/orderReferencesStore';
import kpiDashboardStore from 'kpiDashboard/@store/kpiStore';
import filterStore from '@common/Filters/@store/filterStore';
import claimsStore from 'kpiDashboard/@store/claimsStore';
import performanceTransportStore from 'kpiDashboard/@store/performanceTransportStore';
import performanceSupplierStore from 'kpiDashboard/@store/performanceSupplier';
import performanceQuantityStore from 'kpiDashboard/@store/performanceQuantityStore';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action) || isRejected(action)) {
      console.warn('We got a rejected action!');
      if (action.error?.message === AuthHelper.unauthorizedStatusCode) {
        return next(logout());
      }

      if (action.payload?.messages) {

        next(addAppNotification({
          kind: 'error',
          messages: action.payload?.messages,
          message: "Error"
        }));
      } else {
        console.warn(action.error.data?.message || action.error.message || 'timeout exceeded');

        next(addAppNotification({
          kind: 'error',
          message: action.error.data?.message || action.error.message || 'timeout exceeded'
        }));
      }
    }

    return next(action)
  }

export const store = configureStore({

  reducer: {
    notification,
    dashboard: dashboardStore,
    app: appReducer,
    auth: authReducer,
    map: mapStore,
    mapDetails: mapDetailsStore,
    mapSettings: mapSettingsStore,
    orderDetail: orderDetailStore,
    orderReferences: orderReferencesStore,
    createOrder: createOrderStore,
    createOrderItems: createOrderItemsStore,
    createOrderEvent: createOrderEventStore,
    createOrderPricing: createOrderPricingStore,
    createOrderAdditionalInfo: createOrderInfoStore,
    reports: reportsStore,
    kpiDashboard: kpiDashboardStore,
    filter: filterStore,
    claims: claimsStore,
    performanceTransport: performanceTransportStore,
    performanceSuppliers: performanceSupplierStore,
    performanceQuantities: performanceQuantityStore,
  },
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({ serializableCheck: false }).concat(rtkQueryErrorLogger);

    if (process.env.NODE_ENV !== 'production') {
      return middlewares.concat(reduxLogger);
    }

    return middlewares;
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
