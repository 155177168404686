import { createTheme, ThemeOptions } from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';

export const themeSettings = {
  dark: {
    boxBackground: 'grey',
  },
  light: {
    boxBackground: '#FEFEFE',
  }
}

export const colorPaletteLight = {
  main: '#F7F7F7',
  miniDrawerColor: '#E5E8EA',
  linkColor: '#F7931E',
}
export const colorPaletteDark = {
  main: '#303030',
  miniDrawerColor: grey[900],
  linkColor: '#F7931E',
}

export const BorderColor = {
  orange: "#fbd3a4",
  red: "#D8A3AB",
  green: "#abedc9",
  blue: "#88c3e5",
  disabled: "#ebedef",
}

export const MarkerColor = {
  green: "#2ED47A",
  blue: "#55ACEE",
}

export const ChartColor = {
  blue: '#0090C8',
  teal: '#0071A1',
  green: '#007363',
  lightGreen: '#4caf50',
  orange: '#F7931E',
  red: '#A01B2E',
};

export const colorPalette = (mode: 'dark' | 'light' | boolean) =>
  mode === 'dark' || mode
    ? colorPaletteDark
    : colorPaletteLight;

export const getMyTheme = (mode: 'dark' | 'light') => themeSettings[mode];

export const ThemeDark = {
  palette: {
    mode: 'dark',
    background: {
      default: colorPaletteDark.main
    },
    primary: {
      main: grey[300]
    },
  }
} as ThemeOptions;
export const ThemeLight = {
  palette: {
    mode: 'light',
    background: {
      default: colorPaletteLight.main,
    },
  }
} as ThemeOptions;

// A custom theme for this app
const theme = (mode: boolean) => createTheme({
  palette: {
    primary: {
      main: '#A01B2E',
    },
    secondary: {
      main: '#F7931E',
    },
    error: {
      main: red.A400,
    },
    ...(mode ? ThemeDark.palette : ThemeLight.palette),
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          paddingTop: 16
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: colorPalette(mode).main
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        color: 'inherit'
      },
      styleOverrides: {
        root: {
          backgroundColor: colorPalette(mode).main,
          boxShadow: 'none',
          paddingTop: 16
        }
      }

    },
    MuiLink: {
      defaultProps: {
        color: colorPalette(mode).linkColor,
        sx: {
          '&.active': {
            background: colorPalette(mode).linkColor,
          }
        }
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: "none"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: 0,
          marginRight: 0
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          "&.Mui-disabled": {
            opacity: 0.3
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: getMyTheme(mode ? "dark" : "light").boxBackground,
          borderRadius: "5px 5px 0 0",
          marginLeft: "4px",
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "6px 15px"
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 3000,
        },
        option: {
          '&:hover': {
            backgroundColor: 'rgba(160, 27, 46, 0.3)',
          },
        },
      }
    },
    MuiPopper: {
      defaultProps: {
        style: {
          zIndex: 1000,
        }
      }
    }
  }
});

export default theme;
